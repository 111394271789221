import React, { memo, useState, useEffect } from 'react'

import "../../styles/jobDeatils.scss"
import { Link, graphql } from 'gatsby'
import InfoJobModal from "../../components/InfoJobModal"
import Img from 'gatsby-image'
import Layout from "../../components/Layout"
import axios from 'axios'
import { Spin } from 'antd'
const Details = ({ data, location }) => {
  let id = location.search.split('=')[1];
  const [position, setPostion] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    axios.get(`https://staging.visiomate.com/jobposition/${id}`).then((res) => {
      setPostion([JSON.parse(res.data.result).data[0]]);
    })
  }, []);
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const backgroundImage = data.careerDetailsBg.childImageSharp.fluid.src
  return (
    <Layout className="details" pageTitle={"Visiomate - Career Opportunity"} descrip = {"Join a team that values innovation, collaboration, and work-life balance. Discover fulfilling career opportunities at Visiomate. Apply now!"}>
      <div className='details-Conatiner'
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className='details-div-for-logo'>
          <Link to='/' >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className='detailsHeader'>
          <h1>
            Join our thriving team as we expand and grow! Are you ready to be a valuable part of our  journey?
          </h1>
        </div>
      </div>
      {position ? <div className='job-details'>

        <div className='div1'>

          <h1>{position[0].display_name}</h1>
          <p>{position[0]?.address_id[1]}</p>
        </div>
        <div className='div2'>
          <button onClick={showModal}>Apply now</button>
          <InfoJobModal
            id={id}
            jobName={position[0].name}
            companyIsEnabled
            url="http://46.101.195.121:8066/email/ContactUs"
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
        </div>
        <div className='div3'>
          <h1>Description</h1>
          <div dangerouslySetInnerHTML={{ __html: position[0].description }}></div>
        </div>
      </div> : <div
        style={{ margin: 'auto', width: '300px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>}
    </Layout>

  )
}

export default memo(Details)
export const query = graphql`
  query CareerDetails {
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careerDetailsBg: file(relativePath: { eq: "Maskgroup8copy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

`
